import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="media-position"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.initSortable()
  }

  initSortable() {
    this.sortableInstance = new Sortable(this.element, {
      animation: 150,
      onEnd: () => this.updatePositions()
    })
  }

  updatePositions() {
    // Update all position inputs based on new order
    this.inputTargets.forEach((input, index) => {
      input.value = index + 1
    })
  }

  // Keep this method for manual position input changes
  handlePositionChange(event) {
    const input = event.target
    const newValue = parseInt(input.value)
    
    if (newValue < 1) {
      input.value = 1
    }

    this.validatePositions()
  }

  validatePositions() {
    const positions = new Set()
    let hasError = false

    this.inputTargets.forEach(input => {
      const value = parseInt(input.value)
      if (positions.has(value)) {
        hasError = true
      }
      positions.add(value)
    })

    if (hasError) {
      this.inputTargets.forEach((input, index) => {
        input.value = index + 1
      })
      M.toast({html: 'Positions have been reordered sequentially', classes: 'orange'})
    }
  }
}
