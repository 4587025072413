// app/javascript/controllers/age_range_controller.js

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["lower", "upper", "type"]
  
  static values = {
    maxMonthsRange: Number,
    errorClass: { type: String, default: 'helper-text orange-text' }
  }

  connect() {
    this.typeTarget.addEventListener('change', this.validate.bind(this))
    
    // Check if either lower and upper is empty
    if (this.lowerTarget.value.trim() === "" || this.upperTarget.value.trim() === "") {
      return;
    }
  
    this.validate()
  }

  disconnect() {
    this.typeTarget.removeEventListener('change', this.validate.bind(this))
  }

  validate(event) {
    const ageRangeLower = parseInt(this.lowerTarget.value)
    const ageRangeUpper = parseInt(this.upperTarget.value)
    const ageRangeType = this.typeTarget.value

    // Check if either lower and upper is empty
    if (this.lowerTarget.value.trim() === "" || this.upperTarget.value.trim() === "") {
      return
    }

    this.removeExistingError()

    if (ageRangeUpper < ageRangeLower) {
      this.showError('Invalid age range')
      return
    }

    if (ageRangeType === 'Months' && (ageRangeUpper - ageRangeLower) > 6) {
      this.showError('Max age range: 6 months')
    }
  }

  showError(message) {
    const errorMessage = document.createElement('span')
    errorMessage.classList.add(...this.errorClassValue.split(' '))
    errorMessage.textContent = message
    this.lowerTarget.parentNode.insertBefore(errorMessage, this.lowerTarget.nextSibling)
  }

  removeExistingError() {
    const existingErrorMessage = this.element.querySelector('.helper-text.orange-text')
    if (existingErrorMessage) existingErrorMessage.remove()
  }
}