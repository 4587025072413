import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-constraint"
export default class extends Controller {
  static targets = ["input"]
  static values = {
    minDate: String,
    maxDate: String,
    followsInput: String,  // ID of input this field must follow
    precedesInput: String  // ID of input this field must precede
  }

  connect() {
    // Always attach the observer even if the input is hidden
    if (!this.hasInputTarget) return;
    this.setupVisibilityObserver();

    // If the input is visible initially, set up constraints immediately.
    if (!this.isHidden()) {
      this.applyConstraints();
    }
  }

  // Applies the static and dynamic constraints if the input is visible
  applyConstraints() {
    if (!this.hasInputTarget || this.isHidden()) return;

    // Set static constraints if provided
    if (this.hasMinDateValue) {
      this.inputTarget.min = this.minDateValue;
    }
    
    if (this.hasMaxDateValue) {
      this.inputTarget.max = this.maxDateValue;
    }

    // Setup dynamic constraints based on other fields
    this.setupDynamicConstraints();
  }

  setupDynamicConstraints() {
    // Setup "follows" constraint
    if (this.hasFollowsInputValue) {
      const previousInput = document.getElementById(this.followsInputValue);
      if (previousInput) {
        previousInput.addEventListener('change', () => {
          if (this.isHidden()) return;
          this.inputTarget.min = previousInput.value;
          if (this.inputTarget.value && this.inputTarget.value < previousInput.value) {
            this.inputTarget.value = previousInput.value;
          }
        });
        
        // Initial setup if previous input has a value
        if (previousInput.value && !this.isHidden()) {
          this.inputTarget.min = previousInput.value;
        }
      }
    }

    // Setup "precedes" constraint
    if (this.hasPrecedesInputValue) {
      const nextInput = document.getElementById(this.precedesInputValue);
      if (nextInput) {
        this.inputTarget.addEventListener('change', () => {
          if (this.isHidden()) return;
          nextInput.min = this.inputTarget.value;
          if (nextInput.value && nextInput.value < this.inputTarget.value) {
            nextInput.value = this.inputTarget.value;
          }
        });
      }
    }
  }

  validate() {
    if (this.isHidden()) return;
    
    const input = this.inputTarget;
    const value = input.value;
    
    if (!value) return;
    
    if (input.min && value < input.min) {
      input.value = input.min;
    }
    
    if (input.max && value > input.max) {
      input.value = input.max;
    }
  }

  isHidden() {
    const element = this.inputTarget;
    return (
      element.style.display === 'none' ||
      element.style.visibility === 'hidden' ||
      element.hidden ||
      element.closest('[hidden]') !== null ||
      element.closest('[style*="display: none"]') !== null ||
      element.closest('[style*="visibility: hidden"]') !== null ||
      !element.offsetParent
    );
  }

  setupVisibilityObserver() {
    // Create a MutationObserver to watch for visibility changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          (mutation.type === 'attributes' && 
          ['style', 'hidden', 'class'].includes(mutation.attributeName))
        ) {
          // When the element becomes visible, apply the constraints.
          if (!this.isHidden()) {
            this.applyConstraints();
          }
        }
      });
    });

    // Watch for attribute changes on the input and its parent elements
    let element = this.inputTarget;
    while (element && element !== document.body) {
      observer.observe(element, {
        attributes: true,
        attributeFilter: ['style', 'hidden', 'class']
      });
      element = element.parentElement;
    }
  }
}