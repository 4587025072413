import {Controller} from "stimulus"
import {get} from "@rails/request.js"
import $ from 'jquery';

// Connects to data-controller="location"
export default class extends Controller {

    connect() {
        const country_selector = $("#countries-select");

        if (!country_selector.val()) {
            country_selector.val('AU').trigger('change');
            const event = new Event('change', { bubbles: true });
            country_selector[0].dispatchEvent(event);
        }

        country_selector.on('select2:select', function () {
            let event = new Event('change', {bubbles: true}) // fire a native event
            this.dispatchEvent(event);
        });
    }

    async loadStates(event) {
        const country = event.target.value;
        if (!country) return;

        if (country === 'AU') {
            this.setFieldsForAU();
        } else {
            this.setFieldsForInternationalCountry();
        }

        const response = await get(`/states?country=${country}`)

        if (response.ok) {
            const data = await response.json;

            const bidderRegistrationState = $('#bidder_registration_state');
            bidderRegistrationState.empty();

            bidderRegistrationState.html(
                data.states.map(state => `<option value="${state[1]}">${state[0]}</option>`).join("")
            ).trigger("change").select2();

            bidderRegistrationState.siblings().first().hide();
        }
    }

    setFieldsForInternationalCountry() {
        $('.pic, .abn').hide();
        $('#pic, #abn, #selling_agent, #selling_agent_name, #selling_agent_ph').removeAttr('required');
        $('#contact_mobile').attr('pattern', '^\\d+$').val('');
    }

    setFieldsForAU() {
        $('.pic, .abn').show();
        $('#pic, #abn, #selling_agent, #selling_agent_name, #selling_agent_ph').attr('required', 'true');
        $('#contact_mobile').attr('pattern', '^04(\s?[0-9]{2}\s?)([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})')
            .val('');
    }
}
