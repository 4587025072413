// app/javascript/controllers/dropzone_controller.js
import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  static values = {
    assessmentId: String,
    media: Array,
    endpointContext: String,
    endpointUrl: String,
    formPrefix: String
  }

  async connect() {
    // Constants for styling and config
    const IMAGE_STYLE = "object-fit: cover;";
    const ORDER_INPUT_STYLE = "width: 60px; border: none; background: transparent; text-align: center; font-size: 16px; font-weight: bold;";
    const DRAG_HANDLE_STYLE = "position: absolute; top: -10px; z-index: 10;";
    const ORDER_CONTAINER_STYLE = "text-align: center; margin-top: -15px;";

    let acceptedFiles = "image/png,image/jpeg,image/jpg,image/webp,video/*";
    let maxFiles = 20;
    let previewTemplate = `
      <div class="col s6 l3 photo">
        <div class="card card-flex sortable-item" style="position: relative; height: 180px;">
          <div class="drag-handle" style="${DRAG_HANDLE_STYLE}">
            <i class="material-icons">drag_indicator</i>
          </div>
          <div class="card-image" style="overflow: hidden;">
            <div class="dz-preview dz-file-preview">
              <div class="dz-details">
                <img class="materialboxed image-sizing" data-dz-thumbnail style="${IMAGE_STYLE}" />
              </div>
            </div>
            <div class="remove btn-floating halfway-fab waves-effect waves-light red" style="top: 5px; right: 5px;" data-dz-remove>
              <i class="material-icons">clear</i>
            </div>
          </div>
        </div>
        <div class="progress">
          <div class="determinate" style="width: 100%" data-dz-uploadprogress></div>
        </div>
        <div class="order-number-container" style="${ORDER_CONTAINER_STYLE}">
          <input type="number" 
                 name="${this.formPrefixValue}[sort_id][]" 
                 class="position-input order-number" 
                 data-media-position-target="input"
                 data-action="change->media-position#handlePositionChange"
                 min="1" 
                 value="0" readonly
                 style="${ORDER_INPUT_STYLE}">
          <input type="hidden" 
                 name="${this.formPrefixValue}[id][]" 
                 class="media-id-input">
        </div>
      </div>
    `;

    if (this.endpointContextValue === "health") {
      acceptedFiles = "image/png,image/jpeg,image/jpg,image/webp";
      maxFiles = 5;
      previewTemplate = `
        <div class="col s6 l3 photo">
          <div class="card card-flex" style="height: 180px;">
            <div class="card-image" style="overflow: hidden;">
              <div class="dz-preview dz-file-preview">
                <div class="dz-details">
                  <img class="materialboxed image-sizing" data-dz-thumbnail style="${IMAGE_STYLE}" />
                </div>
              </div>
              <div class="remove btn-floating halfway-fab waves-effect waves-light red" style="top: 5px; right: 5px;" data-dz-remove>
                <i class="material-icons">clear</i>
              </div>
            </div>
          </div>
          <div class="progress">
            <div class="determinate" style="width: 100%" data-dz-uploadprogress></div>
          </div>
        </div>
      `;
    }
    const endpointContext = this.endpointContextValue;
    const dropzoneConfig = {
      url: this.endpointUrlValue,
      method: "post",
      clickable: true,
      paramName: "media",
      maxFilesize: 250,
      parallelUploads: 1,
      uploadMultiple: false,
      autoProcessQueue: true,
      dictDefaultMessage: "Drag and drop images/videos here or click to upload",
      addRemoveLinks: false,
      acceptedFiles: acceptedFiles,
      maxFiles: maxFiles,
      previewsContainer: `.${endpointContext}-dropzone-previews`,
      previewTemplate: previewTemplate,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      init: function() {
        let processingFile = false;

        // Handle new file added
        this.on("addedfile", function(file) {

          if (file.type.match(/image.*/)) {
            // Count the number of existing image files
            const imageFiles = this.files.filter(f => f.type.match(/image.*/)).length;

            // If there are already 8 images, prevent adding the new file and alert the user
            if (imageFiles > 8) { // Using > 8 because the current file has already been added to the count
              this.removeFile(file); // Remove the newly added file
              M.toast({html: "Failed to upload the file, max 8 images allowed", classes: 'red lighten-1'})
              return; // Stop further execution
            }
            
          }
          if (file.type.match(/video.*/)) {
            const video = document.createElement("video");
            video.preload = 'metadata';
            video.muted = true; // Mute the video
            video.onloadedmetadata = function() {
              window.URL.revokeObjectURL(video.src);
              const duration = video.duration;
              if (duration > 180) { // Replace 180 with your desired maximum duration in seconds
                this.removeFile(file);
                alert("Video is too long. Please select a video that is 180 seconds or less.");
              }
            }.bind(this);
            if (file.dataURL) {
              video.src = file.dataURL;
            } else {
              video.src = URL.createObjectURL(file);
            }
        
            // Create a video preview
            const previewElement = file.previewElement;
            const thumbnailElement = previewElement.querySelector("[data-dz-thumbnail]");
            thumbnailElement.parentNode.replaceChild(video, thumbnailElement);
            
            // Add Styling to the video
            video.classList.add("responsive-video");
            video.classList.add("materialboxed");
            video.style.width = '100%'; // Set the width
            video.style.height = 'auto'; // Set the height
            
            // Add video controls
            video.controls = true;
          } else {
            // Initialize materialbox with proper Materialize check
            if (window.M && window.M.Materialbox) {
              window.M.Materialbox.init(document.querySelectorAll('.materialboxed'))
            } else {
              // Wait for Materialize to be available
              const checkInterval = setInterval(() => {
                if (window.M && window.M.Materialbox) {
                  window.M.Materialbox.init(document.querySelectorAll('.materialboxed'))
                  clearInterval(checkInterval)
                }
              }, 100)

              // Stop checking after 5 seconds
              setTimeout(() => {
                clearInterval(checkInterval)
                if (!window.M?.Materialbox) {
                  console.error("Materialize Materialbox not available after timeout")
                }
              }, 5000)
            }
          }
        });

        // Add processing event handler
        this.on("processing", function(file) {
          console.log('Processing file:', file.name);
        });

        // Success event handler
        this.on("success", function(file, response) {
          if (response && response.id) {
            console.log('Upload success:', response);
            M.toast({html: 'Uploaded image successfully'})
            file.id = response.id;
        
            // Find the preview element for the uploaded file
            const previewElement = file.previewElement;
        
            // Update image/video source
            const imageElement = previewElement.querySelector("[data-dz-thumbnail]");
            if (imageElement) {
              imageElement.src = response.service_url;
            }
        
            const videoElement = previewElement.querySelector("video");
            if (videoElement) {
              videoElement.src = response.service_url;
            }

            // Update position input and hidden media id
            const positionInput = previewElement.querySelector(".position-input");
            const mediaIdInput = previewElement.querySelector(".media-id-input");
            if (positionInput && mediaIdInput) {
              // Get all existing position inputs
              const existingInputs = document.querySelectorAll('.position-input');
              const positions = Array.from(existingInputs)
                .map(input => parseInt(input.value))
                .filter(value => !isNaN(value));
              
              // Set the new position as the highest existing position + 1
              const nextPosition = positions.length > 0 ? Math.max(...positions) + 1 : 1;
              
              positionInput.value = nextPosition;
              mediaIdInput.value = response.id;
            }
          } else {
            M.toast({html: "No ID found in the response", classes: 'red lighten-1'})
          }
        }.bind(this));

        // Add upload progress handler
        this.on("uploadprogress", function(file, progress) {
          console.log(`Upload progress: ${progress}%`);
        });

        // Error handler
        this.on("error", function(file, errorMessage) {
          console.error('Upload error:', errorMessage);
          M.toast({html: typeof errorMessage === 'string' ? errorMessage : "Upload failed", classes: 'red lighten-1'});
          this.removeFile(file);
        });

        // Event listener for file removal
        this.on("removedfile", function(file) {
          // Check if the file has an ID (meaning it's already uploaded)
          if (file.id) {
            // Send a DELETE request to your server
            fetch(`${this.options.url}/${file.id}`, {
              method: 'DELETE',
              headers: {
                "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
                "Content-Type": "application/json"
              },
              credentials: 'include' // Include cookies
            })
            .then(response => {
              
              if (response.ok) {
                M.toast({html: 'File deleted successfully'})
              } else {
                console.log(response)
                M.toast({html: "Failed to delete the file", classes: 'red lighten-1'})
                // Optionally, re-add the file preview if the deletion failed
              }
            })
            .catch(error => M.toast({html: "Failed to delete the file", classes: 'red lighten-1'}));
          }
        });

        this.on("maxfilesexceeded", function(file) {
          this.removeFile(file); // Automatically remove files that exceed the maxFiles limit
        });
      },
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);

    // Sort the mediaValue array in ascending order by id
    this.mediaValue.sort((a, b) => a.id - b.id);

    for (const media of this.mediaValue) {
      const response = await fetch(media.service_url);
      const blob = await response.blob();
      
      const mockFile = { 
        id: media.id,
        name: media.filename, 
        size: blob.size, 
        type: blob.type,
        accepted: true,
        dataURL: media.service_url,
        sort_id: media.sort_id
      };
    
      // Add the mock file to Dropzone
      this.dropzone.emit("addedfile", mockFile);
      this.dropzone.emit("thumbnail", mockFile, mockFile.dataURL);
      
      // Update the position number for existing files
      const previewElement = mockFile.previewElement;
      if (previewElement) {
        const positionInput = previewElement.querySelector(".position-input");
        const mediaIdInput = previewElement.querySelector(".media-id-input");
        if (positionInput && mediaIdInput) {
          positionInput.value = media.sort_id || this.getNextAvailablePosition();
          mediaIdInput.value = media.id;
        }
      }
      
      this.dropzone.emit("complete", mockFile);
    }
  }

  getNextAvailablePosition() {
    const existingInputs = document.querySelectorAll('.position-input');
    const usedPositions = Array.from(existingInputs)
      .map(input => parseInt(input.value))
      .filter(value => !isNaN(value));
    
    return usedPositions.length > 0 ? Math.max(...usedPositions) + 1 : 1;
  }
}