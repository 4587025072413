import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "toggle"];
  static values = { compare: String };

  connect() {
    this.toggle(); // check initial state
  }

  toggle(event) {
    // Always read from the inputTarget if available, otherwise fall back to event.target
    let currentValue = "";
    if (event && event.target) {
      currentValue = event.target.value;
    } else if (this.hasInputTarget) {
      currentValue = this.inputTarget.value;
    }
    currentValue = currentValue.trim().toLowerCase();
    const compareValue = this.compareValue.trim().toLowerCase();

    if (currentValue === compareValue) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.toggleTarget.style.display = "";
  }

  hide() {
    this.toggleTarget.style.display = "none";
  }
}