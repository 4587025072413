import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field" ]
  static values = {
    hasDescription: Boolean
  }

  connect() {
    this.update();
  }

  update() {
    let total = 0;
    this.fieldTargets.forEach((field) => {
      const value = Number(field.value);
      if (!isNaN(value)) {
        total += value;
      }
    });

    const headcountElement = document.getElementById('assessment_headcount');
    const headcount = Number(headcountElement.value);

    const difference = headcount - total;

    this.fieldTargets.forEach((field) => {
      const value = Number(field.value);
      if (!isNaN(value)) {
        const percentage = (value / headcount) * 100;
        
        // Get all sibling spans
        const siblings = field.parentElement.querySelectorAll('.helper-text');
        
        // Find the percentage span based on whether there's a description
        const hasDescription = field.dataset.balanceHasDescriptionValue === 'true';
        const percentageSpan = hasDescription ? siblings[2] : siblings[1];
        
        // Update the percentage text and classes
        if (percentageSpan) {
          percentageSpan.textContent = `${percentage.toFixed(0)}%`;
          
          const colorClass = difference === 0 ? 'green-text' : 'orange-text';
          percentageSpan.classList.remove('green-text', 'orange-text');
          percentageSpan.classList.add(colorClass);
        }
      }
    });
  }
}